import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { Button, LinearProgress, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, getHeadersFile } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";

const AddFile = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [values, setValue] = useState({
    image: null,
  });

  const [image, setImage] = useState(null);

  const [error, setError] = useState(false);

    const handleChange = (e) => {
        console.log(e.target.files[0]);
        setImage(e.target.files[0])
    };

  const store = (event) => {
    event.preventDefault();
    setError(false)
    
    //setLoadingOverlayState(true);
    
    const url = `${settings().apiUrl}/api/orders/add/file/${props.editDialogData.id}`;
    const headers = getHeadersFile();

    const data = new FormData() 
    data.append('image', image)
    data.append('id', props.editDialogData.id)

    axios.defaults.headers.common["Authorization"] = headers.Authorization;
    axios
      .post(url, data, {})
      .then((response) => {
        setLoadingOverlayState(false);
        const { data } = props;
        data[props.editKey] = { ...response.data.data };

        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Arquivo anexado com sucesso',
        });
        setDialogState({
            ...dialog,
            open: false,
        });
        setSubmitting(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }

  return (
    <FormWrapper onSubmit={(event) => store(event)}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
            <input required type="file" name="image" className="form-control input-file" placeholder="Arquivo" value={values.image} onChange={(e) => handleChange(e)} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <SaveButton
            type="submit"
            disabled={isSubmitting}
          />
        </Box>
      </Grid>
    </FormWrapper>
  );
};
export default AddFile ;
