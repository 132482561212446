import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
import ReproveButton from "../../components/Buttons/ReproveButton";

import ExceptionAdd from "./ExceptionAdd";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import AddButton from "../../components/Buttons/AddButton";

const Exception: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [stores, setStores] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [company, setCompany] = useState<any>({stores: []});

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    let url = `${settings().apiUrl}/api/orders/exception`;
    let headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/stores`;
    axios
      .get(url, { headers })
      .then((response) => {
        setStores([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

  }, []);

  const deleteItem = (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/orders/exception/${rowData.id}`;
    const headers = getHeaders();

    axios
      .delete(url, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/orders/exception`;
    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const addDialog = <ExceptionAdd setData={setData} data={data} stores={stores} />;

  const columns = [
    {
      label: 'N.º',
      name: "id",
    },
    {
      label: "Empresa",
      name: "company_name",
    },
    {
      label: "Sigla",
      name: "store_initials",
    },
    {
      label: 'Dia de Pedido',
      name: "exception_request_date",
    },
    {
      label: 'Dia de Entrega',
      name: "exception_delivery_date",
    },
    {
      label: 'Total para Pedido',
      name: "exception_request_total",
    },
    {
      label: 'Por Custo Extra',
      name: "exception_extra_fee",
    },
    {
      label: ' ',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <ReproveButton
            onClick={() =>
              setConfirmationDialogState({
                ...confirmationDialog,
                open: true,
                question: `Você tem certeza que deseja esse pedido de exceção?`,
                action: () => deleteItem(data[tableMeta.rowIndex]),
                transition: "default",
              })
            }
          />
        ),
      },
    },

  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Pedidos de Emergência'}</TitleHeader>}
          button={
            <AddButton
              onClick={() =>
                setDialogState({
                  ...dialog,
                  open: true,
                  title: 'Adicionar Pedido Emergencial',
                  content: addDialog,
                  maxWidth: "xl",
                  transition: "regular",
                })
              }
            >
              {'Adicionar Pedido Emergencial'}
            </AddButton>

          }
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Exception;
